import React from 'react'
import styled from 'styled-components'
import blue from '../../page/config/blue.svg'
import Tilt from 'react-parallax-tilt';
import { motion } from 'framer-motion';

const H = styled.h2`
    color: #FFF    ;
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    z-index: 2;
    @media only screen and (max-width: 768px) {
        font-weight: bold;
        font-size: 1.2rem;
      }
`;

const T = styled.p`
    color: #FFF    ;
    font-weight: 500;
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
    z-index: 2;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
      }
`;


const InvisibleMCard = (props) => {

    const Box = styled(motion.div)`
        height: 18rem;
        width: 100%;
        display : flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 2rem 1rem;
      
    `;

    const Upper = styled.div`
        height: 50%;
        width: 100%;
        border-radius: 8px;`;

    const Lower = styled.div`
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding-left: 0.5rem;
        `;


    return (
        // <Tilt>
            <Box>
                <Upper>
                    <img src={`${props.img? props.img : ""}`} alt="" />
                </Upper>
                <Lower>
                    <H>{`${props.name? props.name : "DOT"}`}</H>
                    <T>{`${props.L1? props.L1 : "An uncommon Polymon in the Polyverse."}`}</T>
                    <T>{`${props.L2? props.L2 : "An uncommon Polymon in the Polyverse."}`}</T>
                    <T>{`${props.L3? props.L3 : ""}`}</T>
                    <T>{`${props.L4? props.L4 : ""}`}</T>
                </Lower>
            {/* <H>{`${props.name? props.name : "DOT"}`}</H>
            <T>{`${props.desc? props.desc : "An uncommon Polymon in the Polyverse."}`}</T> */}
            </Box>
        // </Tilt>
    )
}

export default InvisibleMCard





// import React from 'react'
// import styled from 'styled-components'
// import blue from '../../page/config/blue.svg'
// import Tilt from 'react-parallax-tilt';
// import { motion } from 'framer-motion';

// const H = styled.h2`
//     color: #013c8a    ;
//     font-size: 1.5rem;
//     margin-bottom: 1.5rem;
//     z-index: 2;
//     font-family: 'Roboto Slab', serif !important;
//     font-weight: 900;
//     @media only screen and (max-width: 600px) {
//         font-weight: 510;
//         font-size: 2rem;
//       }
// `;

// const T = styled.p`
//     color: #013c8a    ;
//     font-weight: 500;
//     font-size: 1.3rem;
//     margin-bottom: 1rem;
//     z-index: 2;
//     @media only screen and (max-width: 600px) {
//         font-weight: 510;
//         font-size: 1rem;
//       }
// `;


// const Card = (props) => {

//     const Box = styled(motion.div)`
//         height: 32rem;
//         width: 25rem;
//         border-radius: 8px;
//         background-image: url(${props.bg? props.bg : blue});
//         background-size: 100% 100%;
//         background-repeat: no-repeat;
//         padding: 70% 2rem 1rem 2rem;
//         text-align: center;
//         z-index: 4;
//         cursor:pointer;
//         @media only screen and (max-width: 600px) {
//             padding:  65% 2rem 0.5rem 2rem;
//             max-height: 27rem;
//             max-width: 25rem;
//         }
//     `;


//     return (
//         // <Tilt>
//             <Box
//                 initial={{ x: 0, y:0}}
//                 whileHover={{ x: 0, y: -25 }}
//                 transition={{ duration: 0.3, 
               
//                 }}
//             >
//             <H>{`${props.name? props.name : "DOT"}`}</H>
//             <T>{`${props.L1? props.L1 : "An uncommon Polymon in the Polyverse."}`}</T>
//             <T>{`${props.L2? props.L2 : "An uncommon Polymon in the Polyverse."}`}</T>
//             <T>{`${props.L3? props.L3 : ""}`}</T>
//             </Box>
//         // </Tilt>
//     )
// }

// export default Card