import React from 'react'
import './App.css'
import './index.css'
import Home from './page/Home/Home'
import NavBar from './components/NavBar/NavBar';
import Page2 from './page/SecondPage/Page2';
import Page3 from './page/ThirdPage/Page3';
import Page4 from './page/ForthPage/Page4';
import Footer from './page/Footer/Footer';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

const App = () => {
  return (
    <>
      <NavBar />
   
          <Home  />       
          {/* <Page2  />
          <Page4  />  */}
        
        
      {/* <Switch>

        <Route path="/" exact>
          <Home />
          <Page2 />
          <Page4 />
        </Route>
        <Route path="/intellect" exact>
          <Page2 />
        </Route>
        <Route path="/roadmap" exact>
          <Page4 />
        </Route>
        
      </Switch> */}
      <Footer />
    </>
  )
}

export default App
