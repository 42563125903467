import React, { useState, useEffect, useRef } from 'react'
import './NavBar.css';
import logo from './logo.svg';
import mlogo from './mlogo.svg';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import BtnImg from './button.svg'
import { Burger, Menu } from '../components';
import FocusLock from 'react-focus-lock';
import { Link, animateScroll as scroll } from "react-scroll";

const H = styled.h3`
  margin: 0.5rem 0 0 1rem;
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.95);
  font-weight: 540 ;
  @media only screen and (max-width: 768px) {
    font-size: 2rem;
  }
`
const Link1 = styled(Link)`
    display: flex;
    margin: 0 2rem 0 10vw;
    align-items: center;
    text-decoration: none;
    
    cursor: pointer;

    -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;

     &:hover, &:focus,&:active {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
      }

      @media only screen and (max-width: 1600px) {
        margin: 0 2rem 0 2rem;
   }
`;

const Link2 = styled(Link)`
    display: flex;
    margin: 0;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    @media only screen and (max-width: 1025px) {
      margin: 0 ;
 }
    @media only screen and (max-width: 768px) {
         margin: 0 1rem;
    }
`;

const Button = styled(motion.button)`
    cursor: pointer;
    height: 3.2rem ;
    width: 10rem;
    font-size: 1.2rem;
    background-color: #005CFF;
    color: #FFF;
    @media only screen and (max-width: 768px) {
      width: 6rem;
    }
    
`;

const Mobile = styled.div`
    display: none;
  @media only screen and (max-width: 768px) {
    display: block;
  }`

const PC = styled.div`
  display: block;
@media only screen and (max-width: 768px) {
  display: none;
}`



const NavBar = () => {

  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = "main-menu";

  const [navbar, setNavbar] = useState(false)

  const changeBackground = () => {
    console.log(window.scrollY)
    if (window.scrollY >= 100) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground)
  })

  return (
    <nav className={navbar ? "navActive" : "nav"} >
      <PC>
        <Link1 to="home" spy={true} smooth={true}  ><img src="/homeright.png" alt="Logo" className="Logo" /> <H>Plateau Finance</H></Link1>
      </PC>
      <Mobile>
        <Link1 to="home" spy={true} smooth={true} style={{ marginRight: "0" }}  ><img src="/homeright.png" alt="mlogo" className="LogoB" /></Link1>
      </Mobile>
      <PC>
        <ul className="list">
          <div class="dropdown inline-block relative">
            {/* <button style={{color:"rgba(140, 140, 140, 1)",fontSize:"1.3rem"}} class="bg-black-300 text-white-900  rounded inline-flex items-center">
                      <span class="mr-2" >Articles</span>
                      <svg style={{height:"2rem",width:"2rem"}} class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/> </svg>
                    </button>
                    <ul class="dropdown-menu absolute hidden text-gray-700 pt-1">
                      <li><a class="rounded-t bg-black-200  py-2 px-4 block whitespace-no-wrap" href="/team" >Team</a></li>
                      <li ><a class="bg-black-200  py-2 px-4 block whitespace-no-wrap" href="/faq" >FAQ</a></li>
                    </ul> */}
          </div>
          <div class="dropdown inline-block relative">
            {/* <button style={{color:"rgba(140, 140, 140, 1)",fontSize:"1.3rem"}} class="bg-black-300 text-white-900  rounded inline-flex items-center">
                      <span class="mr-2" >Social</span>
                      <svg style={{height:"2rem",width:"2rem"}} class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/> </svg>
                    </button>
                    <ul class="dropdown-menu absolute hidden text-gray-700 pt-1">
                      <li><a class="rounded-t bg-black-200  py-2 px-4 block whitespace-no-wrap" href="/team" >Team</a></li>
                      <li ><a class="bg-black-200  py-2 px-4 block whitespace-no-wrap" href="/faq" >FAQ</a></li>
                    </ul> */}
          </div>
          <a href="https://plateau-finance.medium.com/" >
            <Link2 onClick={() => { window.open("https://plateau-finance.medium.com/") }} href="https://plateau-finance.medium.com/ " spy={true} smooth={true}>Learn More</Link2>
          </a>

          <Button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.7 }}
            type="button"
            onClick={() => window.open("https://app.plateau.finance/#/avax")}
          >
            Launch App
          </Button>

        </ul>
      </PC>

      <Mobile ref={node}>
        <FocusLock disabled={!open}>
          <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
          <Menu open={open} setOpen={setOpen} id={menuId} />
        </FocusLock>
      </Mobile>

    </nav>
  )
}

export default NavBar
