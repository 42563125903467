import React from 'react'
import './Page2.css'
import styled from 'styled-components'
import bg from './bg2.png'
import { FcPlus } from 'react-icons/fc';
import { FaMinusCircle } from 'react-icons/fa';
import { motion } from 'framer-motion';
import mint from './mint.png'
import {data} from '../config/config'
import {data2} from '../config/config2'
import InvisibleMCard from '../../components/Card/Card'
import InvisibleCard from '../../components/Card/InvisibleCard'
import Brain from './brain.svg'

const Page = styled.section`
    display: grid;
    place-items: center;
    min-height: 100vh;
    width: 100%;
    background-image: url(${bg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 8rem 0 3rem 0;
    @media only screen and (max-width: 768px) {
        min-height: 100vh;
        width: 100%;
        padding: 2rem 0.5rem 2rem 0.5rem;
        background-size: auto 100%;
        flex-direction: column;
    }
`;
const BigCard = styled.div`
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #003070;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    padding: 80px 200px 68px 200px;
    border-radius: 2rem;
    margin-bottom: 100px;
    @media only screen and (max-width: 1000px) {
        width: 95%;
        padding: 2rem 2rem;
    }
    @media only screen and (max-width: 768px) {
        width: 95%;
        padding: 2rem 2rem;
    }
`
const H = styled.h2`
    color: #FFF;
    font-weight: bolder;
    font-size: 3rem;
    @media only screen and (max-width: 768px) {
        font-weight: 550;
        font-size: 1.2rem;
        text-align: center;
      }
`;


const Hu = styled.h2`
    color: #FFF;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 3rem;
    @media only screen and (max-width: 768px) {
        font-weight: bold;
        font-size: 1.3rem;
        text-align: center;
      }
`;

const T = styled.p`
    color: #FFF;
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    @media only screen and (max-width: 768px) {
        font-weight: bold;
        font-size: 1.5rem;
      }
`;

const Tu = styled.p`
    color: #fbcf2c;
    margin-bottom: 1rem ;
    font-weight: bold;
    font-size: 20px;
    @media only screen and (max-width: 768px) {
        font-weight: bold;
        font-size: 1rem;
      }
`;

const Td = styled.p`
    color: #fbcf2c;
    margin-bottom: 1rem ;
    font-weight: bold;
    font-size: 20px;
    @media only screen and (max-width: 768px) {
        font-weight: bold;
        font-size: 1rem;
      }
`;

const SmBox = styled.div`
      margin: 0 2rem 0 0;
      width: 14rem;
      @media only screen and (max-width: 768px) {
        margin: 2rem 0 0 0;
        width: 100%;
      }
`;

const Upper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #FFF;
    width: 100%;
    border-radius: 1rem 1rem 0 0;
    padding: 0 0.5rem;
    height: auto;
    @media only screen and (max-width: 768px) {
        width: 100%;
        height: auto;
        justify-content: center;
      }
`;

const Lower = styled.div`
    display: grid;
    place-items: center;
    background-color: #c6250a;
    width: 100%;
    border-radius: 0 0 1rem 1rem ;
    @media only screen and (max-width: 768px) { 
        width: 100%;
        height: auto;
      }
`;

const BigBox = styled.div`
    min-width: 15rem;
    min-height: 8rem;
    border: 2px solid #013C8A;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 2rem;
    margin-top: 0rem;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        min-height: auto;
        margin-top: 2rem;
        padding: 2rem 0;
        width: 100%;
    }
`;

const Divider = styled.div`
      height: 6rem;
      width: 3px;
      background-color: #013C8A;
      margin: 0 1rem;
      @media only screen and (max-width: 768px) {
        margin: 2rem 0 0 0;
        flex-direction: column;
        width: 100%;
        height: 2px;
    }
`
const Left = styled.div`
     margin: 0 1rem 0 0;
     @media only screen and (max-width: 768px) {
        margin: 0 0 0 0;
        display: grid;
        place-items: center;
     }
`;

const Right = styled.div`
      margin:0 0 0 1rem;
      @media only screen and (max-width: 768px) {
        margin: 2rem 0 0 0;
        display: grid;
        place-items: center;
      }
`;

const BoxContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin: 60px 0;
    @media only screen and (max-width: 768px) {
        flex-direction: column-reverse;
        margin: 0;
    }
`;

const Button = styled(motion.div)`
    height: 6rem;
    width: 25rem;
    cursor: pointer;
    margin-top: 0rem;
    @media only screen and (max-width: 768px) {
        height: 6rem;
        width: 80%;
        margin-top: 2rem;
    }
    @media only screen and (max-width: 600px) {
        height: 4rem;
        width: 100%;
        margin-top: 2rem;
    }
`;

const Grid = styled.div`
    display: grid;
    width: 80%;
    height: auto;
    grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
    place-items: center;
    position: relative;
    z-index: 4;
    grid-gap: 30px;
    @media only screen and (min-width: 1100px) {
        width: 100%;
        grid-template-columns: repeat(4, minmax(20%, 1fr));
        grid-gap: 10px;
    }
    @media only screen and (max-width: 768px) {
        display: none;
    }
`;

const MobileGrid = styled.div`
    display: none;
    @media only screen and (max-width: 768px) {
        display: grid;
        width: 100%;
        height: auto;
        grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
        place-items: center;
        position: relative;
        z-index: 4;
        
    }
`;

const ImgContainer = styled.div`
    position: absolute;
    right: 0;
    width: 40rem;
    margin-top: 20%;
    z-index: 1;
    display: block;
    @media only screen and (max-width: 768px) {
        width: 15rem;
        display: none;
      }
`;

const Page2 = () => {
    return (
        <Page id="intellect">
            <BigCard>
                <H><span style={{fontWeight:"bold"}}>7,420 </span> Intellects Remaining</H>

                <BoxContainer>
                    <SmBox>
                        <Upper>
                            <FaMinusCircle className="iconL"/>
                                 <H style={{color:"#000080",marginTop:"0.5rem",fontSize:"3rem"}}>0</H> 
                            <FcPlus className="iconR"/>
                        </Upper>
                        <Lower>
                            <T style={{margin:"1rem 0"}}>Max 20</T>
                        </Lower>
                    </SmBox>
                    <BigBox>
                        <Left>
                            <Tu >COST</Tu>
                            <Hu style={{margin:"0"}} >.0420 eth</Hu>
                        </Left>
                        <Divider />
                        <Right>
                            <Td>TOTAL COST</Td>
                            <Hu style={{margin:" 0"}}>0.6888 + GAS</Hu>
                        </Right>
                    </BigBox>
                </BoxContainer>

                <Button
                    whileHover={{ scale: 1.1}}
                    whileTap={{ scale: 0.7 }}
                ><img src={mint} alt="" style={{width:"100%",height:"100%"}} /> </Button>
            </BigCard>

            <Grid>
            {/* {data?.map(e => (
                <Card 
                    bg={e.img}
                    name={e.name}
                    L1={e.L1}
                    L2={e.L2}
                    L3={e.L3}
                /> 
            ))} */}
            {data?.map(e => (
                <InvisibleMCard 
                    img={e.img}
                    name={e.name}
                    L1={e.L1}
                    L2={e.L2}
                    L3={e.L3}
                    L4={e.L4}
                /> 
            ))}
             </Grid>   

            <MobileGrid>
            {data2?.map(e => (
                <InvisibleCard 
                    img={e.img}
                    name={e.name}
                    L1={e.L1}
                    L2={e.L2}
                    L3={e.L3}
                /> 
            ))}
             </MobileGrid>   

            {/* <ImgContainer>
                <img src={Brain} alt="" style={{width:"100%",height:"100%"}} />
            </ImgContainer>  */}
        </Page>
    )
}

export default Page2
