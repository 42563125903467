import React from 'react'
import './Home.css'
import styled from 'styled-components'
import bg from './homebg.png'
import right from './homeright.png'
import img1 from './cc.png'
import img2 from './ccc.png'
import { motion } from 'framer-motion'
import Title from './title.svg'
import Scroll from './scroll.svg'
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from 'react-alice-carousel';

const HomePage = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    // background-image: url(${bg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;   // z-index works with position like absolute,fixed or relative //
  
    @media only screen and (max-width: 1100px) {
        padding: 10rem 0 2rem 0;
    }

    @media only screen and (max-width: 768px) {
        min-height: 50rem;
        width: 100%;
        padding: 4rem 0.5rem 2rem 0.5rem !important;
        background-size: auto 100%;
        flex-direction: column;
        
    }
`;

export const Width = styled.div`
      width: 1600px;
      display: flex;
        
        align-items: center;
        justify-content: space-evenly;
      @media only screen and (max-width: 1700px) {
        width: 90%;
      }
    @media only screen and (max-width: 1400px) {
        width: 95%;
    }
    @media only screen and (max-width: 1100px) {
        flex-direction: column;
    }

`

const Left = styled.div`
        display: flex;
        flex-direction: column;
        height: auto;
        margin-top:50px;
@media only screen and (max-width: 1100px) {
        padding: 2rem;
        width: 100%;
        text-align: center;
        align-items: center;
    }
`;

const Right = styled(motion.div)`
    width: 30rem; 
    height: 30rem;
    background-image: url(${right});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    top: 10rem;
    transition:  0.5s linear;
    
    @media only screen and (max-width: 1600px) {
        width: 26rem; 
        height: 26rem;
        top: 11rem;
        transition:  0.5s linear;
    }
  
    @media only screen and (max-width: 1400px) {
        
        transition:  0.5s linear;
        width: 24rem; 
        height: 24rem;
    }
    @media only screen and (max-width: 1200px) {
        
        width: 22rem; 
        height: 22rem;
        transition:  0.5s linear;
    }

    @media only screen and (max-width: 768px) {
        width: 18rem;
        height: 18rem;
        margin: 2rem 0 0 0;
    }
    @media only screen and (max-width: 500px) {
        width: 15rem;
       height: 15rem;
    }
    
`;

const HeroImg = styled(motion.div)`
    width: 30rem;
    margin-bottom: 1.5rem;
    transition:  0.5s linear;
    border-radius: 0.5rem;
    cursor: grab;
    @media only screen and (max-width: 1600px) {
        width: 20rem;
        transition:  0.5s linear;
    }
    @media only screen and (max-width: 1400px) {
        width: 19rem;
        transition:  0.5s linear;
    }
    @media only screen and (max-width: 1400px) {
        width: 18rem;
        transition:  0.5s linear;
    }
    @media only screen and (max-width: 1200px) {
        width: 16rem;
        transition:  0.5s linear;
    }
    @media only screen and (max-width: 768px) {
        width: 15rem;
        transition:  0.5s linear;
      }
`;

const T = styled.p`
    color:  rgba(0, 0, 0, 0.9);
    font-weight: 500;
    font-size: 32px;
    line-height: 43.71px;
    margin-bottom: 2rem;
    @media only screen and (max-width: 1200px) {
        font-size: 26px;
        line-height: 38.71px;
      }
    
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1.2rem;
        line-height: 26.71px;
      }
`;

const Button = styled(motion.button)`
    cursor: pointer;
    height: 4rem ;
    width: 22rem;
    font-size: 1.6rem;
    font-weight: 550;
    background-color: #005CFF;
    color: #FFF;
    @media only screen and (max-width: 768px) {
      width: auto;
      padding: 0 2rem;
    }
    
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    @media only screen and (max-width: 650px) {
        flex-direction: column !important;
        
      }
`
const Image1 = styled(motion.div)`
    height: 4rem;
    width: 16rem;
    border-radius: 0.5rem;
    cursor: grab;
    @media only screen and (max-width: 650px) {
        
        height: 2rem;
        width: 8rem;
      }
`
const Image2 = styled(motion.div)`
    height: 3.5rem;
    width: 20rem;
    border-radius: 0.5rem;
    margin-left: 2rem;
    cursor: grab;
    @media only screen and (max-width: 650px) {
        margin: 2rem 0 0 0;
        height: 1.75rem;
        width: 10rem;
      }
`

const Home = () => {
    return (
        <HomePage id="home">
            <Width>
                <Left>
                    <HeroImg
                        whileHover={{ scale: 1.1, boxShadow: "0 0 15px #005CFF" }}
                    >
                        <img src={Title} alt="" style={{ width: "100%", height: "100%" }} />
                    </HeroImg>
                    <T>Allowing users to reap more crypto <br /> with crypto.</T>
                    <Button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.7 }}
                        type="button"
                        onClick={() => window.open("https://app.plateau.finance/#/avax")}
                    >Launch App</Button>
                    <Row>
                        <T style={{ fontSize: "1rem", margin: "2rem 0" }}>Built on Avalanche</T>


                    </Row>
                    <Row>
                        <a href="https://www.coingecko.com/en/coins/plateau-finance">
                            <Image1
                                whileHover={{ scale: 1.1, boxShadow: "0 0 15px #005CFF" }}
                            >
                                <img src={img1} alt="" style={{ height: "100%", width: "100%" }} />
                            </Image1>
                        </a>

                        <a href='https://coinmarketcap.com/currencies/plateau-finance/'>
                            <Image2
                                whileHover={{ scale: 1.1, boxShadow: "0 0 15px #005CFF" }}
                            >
                                <img src={img2} alt="" style={{ height: "100%", width: "100%" }} />
                            </Image2>
                        </a>
                    </Row>
                    <Row style={{ marginTop: "15px" }}>
                        <a href='https://whattofarm.io/'>
                            <Image2
                                whileHover={{ scale: 1.1, boxShadow: "0 0 15px #005CFF" }}
                            >
                                <img src="/wanttoknow.png" alt="" style={{ height: "100%", width: "100%" }} />
                            </Image2>
                        </a>
                    </Row>
                </Left>
                <Right
                    initial={{ x: 0, y: 0 }}
                    animate={{ x: 0, y: -15 }}
                    transition={{
                        duration: 1, repeat: Infinity, repeatType: "reverse",

                    }}
                />
            </Width>

        </HomePage>
    )
}

export default Home
