import React from 'react'
import styled from 'styled-components'
import logo from './logo.png';
import twitter from './twi.svg'
import d from './dis.svg'
import opensea from './reddit.svg'
import t from './tele.png'
import g from './git.png'
import m from './med.png'
import { Link, animateScroll as scroll } from "react-scroll";
import { motion } from 'framer-motion';

const Foot = styled.div`
    background-color: #000;
    min-height: 9rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 10% 0.5rem 10%;
  
    @media only screen and (max-width: 1600px) {
        flex-direction: column;
        min-height: auto;
        padding: 1.5rem;
   }
`;

const Link1 = styled(Link)`
    display: flex;
    margin: 0 2rem;
    align-items: center;
    text-decoration: none;
    color: #FFF;
    cursor: pointer;


      
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;

     &:hover, &:focus,&:active {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
      }


    @media only screen and (max-width: 768px) {
         margin: 0 1rem;
    }
`;


const Right = styled.div`
    display: flex;
    align-items: center;
    width: 898px;
    justify-content: space-between;
    @media only screen and (max-width: 1600px) {
        margin: 1rem 0 2rem 0;
   }
   @media only screen and (max-width: 950px) {
       width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
    place-items: center;
}

`;

const Img = styled(motion.img)`
    width: 2.5rem;
    cursor: pointer;
    @media only screen and (max-width: 950px) {
        margin: 2rem 0;
 }

`
const T = styled.p`
    color: #FFF;
    font-weight: 500;
    font-size: 16px;
    margin-top: 1rem;
    @media only screen and (max-width: 1100px) {
        font-weight: 510;
        font-size: 0.8rem;
        
      }
    text-align: center;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 0.8rem;
        
      }
`;



const Footer = () => {
    return (
        <Foot>
            <Link1 to="home" spy={true} smooth={true}><img src={logo} alt="Logo" /></Link1>

            <Right>
                <a href="https://twitter.com/plateau_finance">

                    <Img
                        style={{ width: "2rem" }}
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.7 }}
                        src={twitter} alt="" />
                </a>
                <a href="https://discord.gg/XyR53pfdFh">
                    <Img
                        style={{ width: "2rem" }}
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.7 }}
                        src={d} alt="" />
                </a>
                <a href="https://plateau-finance.gitbook.io/plateau-finance/">
                    <Img
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.7 }}
                        src={opensea} alt="" />
                </a>
                <a href="https://t.me/plateau_fin">
                    <Img
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.7 }}
                        src={t} alt="" />
                </a>
                <a href="https://github.com/Plateau-Finance">
                    <Img
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.7 }}
                        src={g} alt="" />
                </a>
                <a href="https://plateau-finance.medium.com/">
                    <Img
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.7 }}
                        src={m} alt="" />
                </a>

            </Right>
        </Foot>
    )
}

export default Footer
